import React, { useEffect, useMemo } from 'react';
import { Row } from 'react-bootstrap';

import PortletBody from '../../../app/components/portlet/PortletBody';
import { StyledSpan, Wrap } from '../../../assets/styledComponents/Global';
// import BagIcon from '../../../app/components/svgIcons/Bagicon';
import styled from 'styled-components';
// import { copywriteType } from '../../Copywriting/assets/utils';
import FormikAsyncSelect from '../../../app/components/formikComponents/FormikAsyncSelect';
import TotalJobIcon from '../../../app/components/svgIcons/TotalJobIcon';
import { vacancyStaffGroup, contractType,advertiseType, bandTypeHospital, bandTypeAgenda, workArea } from '../CampaignsUtils';
import FormikInputField from '../../../app/components/formikComponents/FormikInputField';
import FormikDatePicker from '../../../app/components/formikComponents/FormikDatePicker';
import StyledInput from '../../../app/components/styledComponents/StyledInput';
import moment from 'moment';


const NhsJobsEditForm = ({values,setFieldValue}) => {
  const options = useMemo(() => {
    if (values?.payScheme?.value === 'Hospital Medical Dental') {
      return bandTypeHospital;
    }
    if (values?.payScheme?.value === 'Agenda for change') {
      return bandTypeAgenda;
    }
    return [];
  }, [values?.payScheme?.value]);
  useEffect(() => {
    if (values?.payScheme?.value === 'Hospital Medical Dental' || values?.payScheme?.value === 'Agenda for change') {
        setFieldValue('bandType', null);
    }

  }, [values?.payScheme?.value]);

  return (
    <>
      <PortletBody className="w-100">
        <div className="p-4">
        <StyledWrap className={`d-flex align-items-center justify-content-start gap-3`}>
          <TotalJobIcon size={24} />
          <Wrap className='d-flex flex-column'>
            <StyledSpan size='16px' className='text fw-semibold'>NHS Jobs</StyledSpan>
          </Wrap>
        </StyledWrap>
          <div className="p-2">
            <Row className="mt-2">
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="contractType" className="form-label">
                  Contract Type
                  </label>
                  <FormikAsyncSelect
                        name="contractType"
                        id="contractType"
                        options={contractType}
                        placeholder="Select contract type"
                        isDisabled={values?.jobBoardSummaryKeys?.nhsObject}
                    />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="sponsorship" className="form-label">
                  Sponsorship (Is the job open to applicants outside the EU and EEA?)
                  </label>
                  <FormikAsyncSelect
                        name="sponsorship"
                        id="sponsorship"
                        options={[{label:'yes',value:'yes'},{label:'no',value:'no'}]}
                        placeholder="Select sponsorship"
                        isDisabled={values?.jobBoardSummaryKeys?.nhsObject}
                    />
                </div>
              </div>
              {/* <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="dbs" className="form-label">
                  Dbs
                  </label>
                  <FormikAsyncSelect
                        name="dbs"
                        id="dbs"
                        options={[{label:'true',value:true},{label:'false',value:false}]}
                        placeholder="Select dbs"
                        isDisabled={values?.jobBoardSummaryKeys?.nhsObject}
                    />
                </div>
              </div> */}
            </Row>
            <Row>
            <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="policeWarning" className="form-label">
                Police Warning (Does the job require applicants to be checked for any previous criminal convictions?)
                  </label>
                  <FormikAsyncSelect
                        name="policeWarning"
                        id="policeWarning"
                        options={[{label:'yes',value:'yes'},{label:'no',value:'no'}]}
                        placeholder="Select eu view"
                        isDisabled={values?.jobBoardSummaryKeys?.nhsObject}
                    />
                </div>
              </div>
            <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="registration" className="form-label">
                  Registration (Does the job require current UK professional registration?)
                  </label>
                  <FormikAsyncSelect
                        name="registration"
                        id="registration"
                        options={[{label:'yes',value:'yes'},{label:'no',value:'no'}]}
                        placeholder="Select registration"
                        isDisabled={values?.jobBoardSummaryKeys?.nhsObject}
                    />
                </div>
              </div>
            </Row>

            <Row className="mt-2">
            <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="vacancyStaffGroup" className="form-label">
                  Vacancy Staff Group
                  </label>
                  <FormikAsyncSelect
                        name="vacancyStaffGroup"
                        id="vacancyStaffGroup"
                        options={vacancyStaffGroup}
                        placeholder="Select vacancy staff group"
                        isDisabled={values?.jobBoardSummaryKeys?.nhsObject}
                    />
                </div>
              </div>
               <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="reason" className="form-label">
                    Reason
                  </label>
                  <FormikAsyncSelect
                        name="reason"
                        id="reason"
                        options={[{label:'New',value:'New'},{label:'Replacement',value:'Replacement'},{label:'Temporary',value:'Temporary'}]}
                        placeholder="Select reason"
                        isDisabled={values?.jobBoardSummaryKeys?.nhsObject}
                    />
                </div>
              </div>
            </Row>

<Row>
            
<div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="advertiseType" className="form-label">
                Advertise Type
                  </label>
                  <FormikAsyncSelect
                        name="advertiseType"
                        id="advertiseType"
                        options={advertiseType}
                        placeholder="Select vacancy staff group"
                        isDisabled={values?.jobBoardSummaryKeys?.nhsObject}
                    />
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="vacancyAreaOfWork" className="form-label">
                  Vacancy Area of Work
                  </label>
                  <FormikAsyncSelect
                        name="vacancyAreaOfWork"
                        id="vacancyAreaOfWork"
                        options={workArea}
                        placeholder="Select area of work"
                        isDisabled={values?.jobBoardSummaryKeys?.nhsObject}
                    />
                </div>
              </div>
            </Row>
            <Row>
           

              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="covidRelated" className="form-label">
                  Covid Related
                  </label>
                  <FormikAsyncSelect
                        name="covidRelated"
                        id="covidRelated"
                        options={[{label:'yes',value:'yes'},{label:'no',value:'no'}]}
                        placeholder="Select covid related"
                        isDisabled={values?.jobBoardSummaryKeys?.nhsObject}
                    />
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="payScheme" className="form-label">
                 Pay Scheme
                  </label>
                  <FormikAsyncSelect
                        name="payScheme"
                        id="payScheme"
                        options={[{label:'Hospital Medical Dental',value:'Hospital Medical Dental'},
                          {label:'Agenda for change',value:'Agenda for change'},
                          {label:'Very senior manager',value:'Very senior manager'},
                          {label:'Other',value:'Other'}]}
                        placeholder="Select covid related"
                        isDisabled={values?.jobBoardSummaryKeys?.nhsObject}
                    />
                </div>
              </div>
              {((!values?.campaignAdvert?.expiresAt || values?.campaignAdvert?.expiresAt?.length === 0) && !values?.jobBoardSummaryKeys?.nhsObject ) &&     <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="validThrough" className="form-label">
                Valid Through
                  </label>
                  <FormikDatePicker
                         key={values?.validThrough} // Force re-render when payScheme changes
                        name="validThrough"
                        id="validThrough"
                        minDate={new Date()}
                        placeholder="Select expires at"
                         onChange={(date) => setFieldValue('validThrough', date)}
                        isDisabled={values?.jobBoardSummaryKeys?.nhsObject}
                    />
                </div>
              </div>}
               {(values?.campaignAdvert?.expiresAt?.length > 0 ||  values?.jobBoardSummaryKeys?.nhsObject) &&
              <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="validThrough" className="form-label">
                Valid Through
                  </label>
                  <StyledInput
                        name="validThrough"
                        id="validThrough"
                        value={values?.campaignAdvert?.expiresAt ? moment(values?.campaignAdvert?.expiresAt).format('DD MMM YYYY') : moment(values?.validThrough).format('DD MMM YYYY')}
                        disabled={true}
                    />
                </div>
              </div>
} 
              {(values?.payScheme?.value === 'Hospital Medical Dental' || values?.payScheme?.value === 'Agenda for change' ) && <div className="col-12 col-md-6">
                <div className="mb-3">
                  <label htmlFor="bandType" className="form-label">
                 Band Type
                  </label>
                  <FormikAsyncSelect
                        key={values?.payScheme?.value} // Force re-render when payScheme changes
                        name="bandType"
                        id="bandType"
                        options={options}
                        placeholder="Select covid related"
                        isDisabled={values?.jobBoardSummaryKeys?.nhsObject}
                    />
                </div>
              </div>
}
              </Row>

          </div>
        </div>
      </PortletBody>
    </>
  );
};

const StyledWrap = styled.div`
margin : 1px 1px 0px 0px;
`;
export default NhsJobsEditForm;